<template>
    <el-form label-position="top" ref="flowFormRef" label-width="80px" :model="form" :rules="rules" class="flow-form">
        <el-form-item prop="name">
            <template #label>
                <div class="flow-form-item">
                    <span class="flow-form-item-label">审批流名称</span>
                    <span class="flow-form-item-path">位置：{{ path }}</span>
                </div>
            </template>
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
            <template #label>
                <div class="flow-form-item">
                    <span class="flow-form-item-label">备注</span>
                </div>
            </template>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.remark">
            </el-input>
        </el-form-item>
    </el-form>
</template>
<script>
const validateFileName = (rule, value, callback) => {
    if (value.trim() === '') {
        callback(new Error('请输入文件夹名称'));
    } else {
        callback();
    }
};
export default {
    name: "create-folder-modal",
    props: {
        path: {
            type: String,
            default: ''
        },
        defaultForm: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false,
            form: {
                name: '',
                remark: ''
            },
            rules: {
                name: [
                    { validator: validateFileName, trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        if (this.defaultForm) {
            this.form = {...this.form, ...this.defaultForm}
        }
    },
    methods: {

        async onOk() {
            try {
                const res = await this.$refs.flowFormRef.validate()
                if (!res) {
                    return
                }
                return this.form
            } catch (error) {
                return false
            }


        },
    },
    expose: ['onOk']
}
</script>
<style lang="less" scoped>
.flow-form {
    width: 540px;
}

.flow-form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 540px;

    &-label {
        height: 16px;
        font-weight: 600;
        font-size: 16px;
        color: #252D3D;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    &-path {
        height: 14px;
        font-weight: 400;
        font-size: 14px;
        color: #5D687C;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}
</style>