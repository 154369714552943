<template>
    <el-button class="create-button" v-on="$listeners">
        <div class="create-button-content">
            <div class="create-button-text">
                <img :src="iconUrl" alt="folder" class="button-folder-icon">
                <span class="create-button-text">
                    <slot></slot>
                </span>
            </div>
            <img :src="createUrl" alt="create" class="create-button-icon">
        </div>
    </el-button>
</template>
<script>
import createUrl from "@/assets/images/workflow/create.svg"

export default {
    name: "create-button",
    props: {
        iconUrl: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            createUrl
        }
    }
}
</script>
<style scoped lang="less">
.create-button {
    box-sizing: border-box;
    width: 250px;
    border-radius: 10px;
    border: 1px solid #E3E8EE;
    padding: 20px 36px 20px 20px;
    background: #FFFFFF !important;
    color: #252D3D !important;

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-text {
        display: flex;
        align-items: center;

        .button-folder-icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }

    &:active,
    &:focus,
    &:hover {
        color: #252D3D;
        border-color: #2A61FF;
    }
}
</style>