var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tree",
    _vm._b(
      {
        staticClass: "tree-container",
        attrs: {
          "node-key": "id",
          "default-checked-keys": _vm.checkedKeys,
          "icon-class": "icon-class",
          data: _vm.treeData,
          props: _vm.defaultProps,
        },
        on: { "node-click": _vm.handleNodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c(
                "div",
                {
                  staticClass: "custom-tree-node",
                  class: { "node-selected": _vm.getSelected(data) },
                  style: { paddingLeft: _vm.getPaddingLeft(data.level) },
                },
                [
                  _c(
                    "div",
                    { staticClass: "node-wrapper" },
                    [
                      !node.isLeaf
                        ? _c("img", {
                            staticClass: "icon-expanded",
                            attrs: {
                              src: node.expanded
                                ? _vm.foldUrl
                                : _vm.expandedUrl,
                            },
                          })
                        : _vm._e(),
                      data.id !== "ROOT"
                        ? [
                            !data.status
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      data.type === _vm.FolderType
                                        ? _vm.folderUrl
                                        : _vm.fileUrl,
                                  },
                                })
                              : _c("img", {
                                  attrs: { src: _vm.fileDisabledUrl },
                                }),
                          ]
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "tree-label",
                          class: { "tree-label-disabled": data.disabled },
                        },
                        [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                          _vm.showCheckbox
                            ? _c("el-checkbox", {
                                staticClass: "checkbox",
                                staticStyle: { margin: "0 30px 0 20px" },
                                on: {
                                  change: (event) =>
                                    _vm.change(event, { node, data }),
                                },
                                model: {
                                  value:
                                    _vm.currentCheckedKeys[
                                      data[_vm.defaultProps.id]
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentCheckedKeys,
                                      data[_vm.defaultProps.id],
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentCheckedKeys[data[defaultProps.id]]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      data.disabled
                        ? _c("span", { staticClass: "tree-tag-disabled" }, [
                            _vm._v("禁用"),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            },
          },
        ]),
      },
      "el-tree",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }