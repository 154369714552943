<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 10:05:41
 * @Description: 
-->
<template>
  <div v-loading="loading" :style="{ width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)', }"
    class="workflow-list-page">
    <div class="tree-box">
      <flow-tree :selected-node="selectedNode" :data="treeData" @node-click="nodeClick"
        :default-expanded-keys="expandedKeys" />
    </div>
    <div class="right">
      <div class="right-top">
        <create-button @click="createFolderClick" :icon-url='folderUrl'>
          <div>新建文件夹</div>
        </create-button>
        <create-button @click="createFileClick" :icon-url='fileUrl'>
          <div>新建审批流</div>
        </create-button>
      </div>

      <div class="workflow-list">
        <div v-if="!workflowList.length" class="empty-box">
          <img :src="emptyUrl" alt="empty">
        </div>
        <div v-for="item in workflowList" :key="item.nodeId" class="workflow-card">

          <div class="workflow-card-content">
            <div class="dropdown-part">
              <el-dropdown @command="(key) => handleCommand(key, item)">
                <div class="ellipsis-btn">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
                <el-dropdown-menu slot="dropdown" class="dropdown-menu" trigger="click" :hide-on-click="false">
                  <template v-if="item.type === FileType">
                    <el-dropdown-item command="publish">发布</el-dropdown-item>
                    <el-dropdown-item command="formConfig">表单配置</el-dropdown-item>
                    <el-dropdown-item :disabled="item.isProcess !== 1" command="formConfig">流程配置</el-dropdown-item>
                    <el-dropdown-item v-if="item.enableStatus === 'ENABLE'" command="disable">禁用</el-dropdown-item>
                    <el-dropdown-item v-if="item.enableStatus === 'DISABLE'" command="enable">取消禁用</el-dropdown-item>
                    <el-dropdown-item command="copy">复制流程</el-dropdown-item>
                    <el-dropdown-item command="publishRecord">发布记录</el-dropdown-item>
                    <el-dropdown-item command="createFolder" v-if="item.type === FolderType">新建文件夹</el-dropdown-item>
                    <el-dropdown-item command="createFile" v-if="item.type === FolderType">新建流程</el-dropdown-item>
                    <el-dropdown-item command="rename">重命名</el-dropdown-item>
                    <el-dropdown-item command="move">
                      移动至文件夹
                    </el-dropdown-item>
                    <el-dropdown-item style="padding: 0">
                      <warning-modal :on-ok="() => handleDelete(item)"
                        class-name="error-text dropdown-menu-item">删除</warning-modal>
                    </el-dropdown-item>
                  </template>
                  <template v-if="item.type === FolderType">
                    <el-dropdown-item command="createFolder">新建文件夹</el-dropdown-item>
                    <el-dropdown-item command="createFile">新建流程</el-dropdown-item>
                    <el-dropdown-item command="rename">重命名</el-dropdown-item>
                    <el-dropdown-item command="move">
                      移动至文件夹
                    </el-dropdown-item>
                    <el-dropdown-item style="padding: 0">
                      <warning-modal :on-ok="() => handleDelete(item)"
                        class-name="error-text dropdown-menu-item">删除</warning-modal>
                    </el-dropdown-item>
                  </template>

                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <img :src="item.type === FileType ? fileUrl : folderUrl" alt="folder" class="workflow-icon ">
          </div>
          <div class="workflow-card-footer">
            <div class="workflow-card-name">
              <img class="name-icon" :src="item.type === FileType ? fileUrl : folderUrl" alt="folder">
              <span>{{ item.nodeName }}</span>
              <div class="workflow-tag-box" v-if="item.type === FileType">
                <span v-if="item.modeStatus === 'PUBLISH'" class="workflow-tag workflow-tag-publish">已发布</span>
                <span v-if="item.modeStatus === 'DRAFT'" class="workflow-tag workflow-tag-draft">草稿</span>
                <span v-if="item.enableStatus === 'DISABLE'" class="workflow-tag-disabled">禁用</span>
              </div>
            </div>
            <div class="workflow-name-date">
              <span>{{ item.updateUserName }}</span>
              <span>{{ item.lastUpdateDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <flow-modal width="600px" title="新建文件夹" :visible="createFolderModalConfig.visible" @onOk="onOkCreateFolder"
        @onCancel="handleCancelCreateFolder" :loading="loading">
        <create-folder-modal v-if="createFolderModalConfig.visible" ref="createFolderModalRef"
          :path="createFolderModalConfig.data.path" />
      </flow-modal>
      <flow-modal width="600px" title="新建审批流" :visible="createFileModalConfig.visible" @onOk="onOkCreateFile"
        @onCancel="handleCancelCreateFile" :loading="loading">
        <create-file-modal v-if="createFileModalConfig.visible" ref="createFileModalRef"
          :path="createFileModalConfig.data.path" />
      </flow-modal>
      <flow-modal width="400px" title="重命名" :visible="renameModalConfig.visible" :data="renameModalConfig.data"
        @onOk="onOkRename" @onCancel="handleCancelRename" :loading="loading">
        <rename-modal ref="renameModalRef" v-if="renameModalConfig.visible"
          :old-name="renameModalConfig.data.oldName" />
      </flow-modal>
      <flow-modal width="600px" title="复制流程" :visible="copyModalConfig.visible" @onOk="onOkCopyModal"
        @onCancel="onCancelCopyModal" :loading="loading">
        <create-file-modal v-if="copyModalConfig.visible" ref="copyModalRef" :path="copyModalConfig.data.path"
          :defaultForm="copyModalConfig.data.form" />
      </flow-modal>
      <flow-modal width="600px" title="移动至文件夹" :visible="moveFileModalConfig.visible" @onOk="onOkMoveFile"
        @onCancel="onCancelMoveFile">
        <move-file-modal v-if="moveFileModalConfig.visible" ref="moveFileModalRef" :node-id="moveFileModalConfig.data.nodeId" />
      </flow-modal>
      <flow-modal width="600px" title="发布" :visible="publishModalConfig.visible" @onOk="onOkPublishModal"
        @onCancel="onCancelPublishModal" :loading="loading">
        <publish-modal v-if="publishModalConfig.visible" ref="publishModalRef" :path="publishModalConfig.data.path" :default-form="publishModalConfig.data.form" />
      </flow-modal>
    </div>

  </div>
</template>

<script>
import folderUrl from "@/assets/images/workflow/folder.svg"
import fileUrl from "@/assets/images/workflow/file-icon.svg"
import expandedUrl from "@/assets/images/workflow/expanded.svg"
import foldUrl from "@/assets/images/workflow/fold.svg"
import fileDisabledUrl from "@/assets/images/workflow/file-disabled.svg"
import { mapGetters } from "vuex";
import CreateButton from "./components/create-button.vue"
import FlowModal from "./components/flow-modal.vue"
import CreateFolderModal from "./components/create-folder-modal.vue";
import CreateFileModal from "./components/create-file-modal.vue"
import WarningModal from "@/views/ruge/workflow/list/components/warning-modal.vue";
import FlowTree from "@/views/ruge/workflow/list/components/flow-tree.vue";
import MoveFileModal from "@/views/ruge/workflow/list/components/move-file-modal.vue";
import { apiGetPreNodeTree } from "@/api/ruge/workflow/workflow"
import { FolderType, FileType, getMessageError, formatTreeData, arrayToTree, getTreeData, RootType } from "./utils"
import { apiPreCategoryNodeList, apiDeleteNode, apiNodeRename, apiPublish, apiCopyMode, apiPostSaveFolderNode, apiPostSaveFileNode, apiEnableDisable, apiMoveNode } from "../../../../api/ruge/workflow/workflow"
import RenameModal from "./components/rename-modal.vue"
import emptyUrl from "@/assets/images/workflow/empty.svg"
import { format } from "date-fns"
import PublishModal from "./components/publish-modal.vue"
export default {
  name: "workflow-list-page",
  components: {
    MoveFileModal,
    CreateFolderModal,
    CreateButton,
    FlowModal,
    CreateFileModal,
    WarningModal,
    FlowTree,
    RenameModal,
    PublishModal
  },
  data() {
    return {
      loading: true,
      FolderType,
      FileType,
      folderUrl,
      fileUrl,
      expandedUrl,
      foldUrl,
      fileDisabledUrl,
      emptyUrl,
      expandedKeys: [],
      /** Config for create file modal */
      createFileModalConfig: {
        visible: false,
        /** 
         * Modal data containing:
         * @property {string} id - Parent folder ID
         * @property {string} path - Full path to current location
         */
        data: {}
      },

      /** Config for create folder modal */
      createFolderModalConfig: {
        visible: false,
        /** 
         * Modal data containing:
         * @property {string} id - Parent folder ID  
         * @property {string} path - Full path to current location
         */
        data: {}
      },

      /** Flag for rename modal visibility */
      visibleRename: false,

      /** Config for rename modal */
      renameModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} oldName - Original node name
         * @property {string} nodeId - ID of node being renamed
         * @property {string} nodeType - Type of node being renamed
         */
        data: {}
      },

      /** Config for copy workflow modal */
      copyModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} id - ID of workflow being copied
         * @property {string} path - Path where copy will be created
         * @property {Object} form - Default form values for copy
         */
        data: {}
      },

      /** Config for move file modal */
      moveFileModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} nodeId - ID of node being moved
         * @property {string} nodeType - Type of node being moved
         * @property {string} path - Current path of node
         */
        data: {}
      },
      publishModalConfig: {
        visible: false,
        /**
        * Modal data containing:
        * @property {string} path - Current path in workflow tree
        * @property {string} name - Name of workflow being published
        * @property {string} nodeId - ID of node being published
        * @property {string} nodeType - Type of node being published
        */
        data: {}
      },
      selectedNode: null,
      btns: [
        { label: '新建文件夹', imgUrl: folderUrl },
        { label: '新建审批流', imgUrl: fileUrl }
      ],
      workflowList: [],

      treeData: [],
      treeDataFlat: [],
    }
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    this.fetchTreeData()
  },
  methods: {
    async onOkPublishModal() {
      const data = await this.$refs.publishModalRef.onOk()
      if (!data) return
      const { nodeId, nodeType } = this.publishModalConfig.data
      try {
        await apiPublish({
          nodeType,
          modeVO: {
            modeId: nodeId,
            modeStatus: 'PUBLISH',
            processName: data.name,
            publishDescription: data.remark
          }
        })
        this.$message.success('发布成功')
        this.onCancelPublishModal()
        this.fetchNodeData(this.selectedNode.id)
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
    },
    onCancelPublishModal() {
      this.publishModalConfig = {
        visible: false,
        data: {}
      }
    },
    async onOkMoveFile() {
      const checkedKeys = await this.$refs.moveFileModalRef.onOk()
      if (!checkedKeys) return
      const { data } = this.moveFileModalConfig
      if (data.nodeType === 'mode' && checkedKeys[0] === RootType) {
        this.$message.error('审批流不能移动到根节点')
        return
      }
      try {

        await apiMoveNode({
          nodeId: data.nodeId,
          nodeType: data.nodeType,
          parentNodeId: checkedKeys[0] === RootType ? '' : checkedKeys[0]
        })
        this.onCancelMoveFile()
        this.$message.success('移动成功')
        this.fetchTreeData()
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
    },
    onCancelMoveFile() {
      this.moveFileModalConfig = {
        visible: false,
        data: {}
      }
    },
    async onOkRename() {
      this.loading = true
      try {
        const data = await this.$refs.renameModalRef.onOk()
        if (!data) return
        this.loading = true
        await apiNodeRename({
          nodeId: this.renameModalConfig.data.nodeId,
          nodeType: this.renameModalConfig.data.nodeType,
          nodeName: data.name
        })
        this.$message.success('重命名成功')
        this.handleCancelRename()
        this.fetchTreeData()
      } catch (error) {
        this.$message.error(getMessageError(error))
      } finally {
        this.loading = false
      }
      this.handleCancelRename()
    },
    handleCancelRename() {
      this.renameModalConfig = {
        visible: false,
        data: {}
      }
    },
    createFolderClick() {
      const data = { id: this.selectedNode.id, path: this.getPath(this.selectedNode) }
      this.createFolderModalConfig = {
        visible: true,
        data
      }
    },
    createFileClick() {
      const id = this.selectedNode.id
      if (id === RootType) {
        this.$message.error('根节点下不能新建审批流')
        return
      }
      const data = { id: this.selectedNode.id, path: this.getPath(this.selectedNode) }
      this.createFileModalConfig = {
        visible: true,
        data
      }
    },
    async onOkCopyModal() {
      try {
        const data = await this.$refs.copyModalRef.onOk()
        if (!data) return
        await apiCopyMode({
          modeId: this.copyModalConfig.data.id,
          processCode: data.code,
          processName: data.name,
        })
        this.$message.success('复制成功')
        this.onCancelCopyModal()
        this.fetchTreeData()
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
    },
    onCancelCopyModal() {
      this.copyModalConfig = {
        visible: false,
        data: {}
      }
    },
    async onOkCreateFolder() {
      try {
        const curNode = this.createFolderModalConfig.data
        const data = await this.$refs.createFolderModalRef.onOk()
        if (!data) return
        const parentId = curNode ? curNode.id : ''
        console.log('data', data);

        await apiPostSaveFolderNode({
          nodeType: 'category',
          categoryVO: {
            categoryName: data.name,
            parentId: parentId === RootType ? '' : parentId
          }
        })
        this.fetchTreeData()
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
      this.handleCancelCreateFolder()
    },
    async onOkCreateFile() {
      try {
        const curNode = this.createFileModalConfig.data
        const data = await this.$refs.createFileModalRef.onOk()
        if (!data) return
        const parentId = curNode ? curNode.id : ''

        await apiPostSaveFileNode({
          nodeType: 'mode',
          modeVO: {
            processName: data.name,
            processCode: data.code,
            categoryId: parentId,
          }
        })
        if (curNode) {
          this.fetchNodeData(curNode.id)
        } else {
          this.fetchTreeData()
        }
        this.handleCancelCreateFile()
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
    },

    handleCancelCreateFolder() {
      this.createFolderModalConfig = {
        visible: false,
        data: {}
      }
    },
    handleCancelCreateFile() {
      this.createFileModalConfig = {
        visible: false,
        data: {}
      }
    },
    fetchTreeData() {
      this.loading = true
      apiGetPreNodeTree().then(res => {
        const data = res.filter(item => item.nodeType !== 'mode')
        this.treeData = formatTreeData(arrayToTree(getTreeData(data)))

        if (this.selectedNode) {
          this.fetchNodeData(this.selectedNode.id)
        } else if (this.treeData.length) {
          this.nodeClick(this.treeData[0])
        }
      }).catch(err => {
        console.error(err)
        this.$message.error(getMessageError(err))
      }).finally(() => {
        this.loading = false
      })
    },
    fetchNodeData(parentId) {
      apiPreCategoryNodeList({ categoryId: parentId })
        .then(res => {
          console.log(res)
          this.workflowList = res.map(item => ({
            ...item,
            type: item.nodeType === 'category' ? FolderType : FileType,
            lastUpdateDate: format(new Date(item.lastUpdateDate), 'yyyy-MM-dd HH:mm')
          }))
          console.log('this.workflowList', this.workflowList);

        }).catch(err => {
          this.$message.error(getMessageError(err))
        }).finally(() => {
          this.loading = false
        })
    },

    onCreateClick() {
      console.log('create')
    },
    nodeClick(data) {
      const id = this.selectedNode ? this.selectedNode.id : null
      if (data.type === FolderType && id !== data.id) {
        this.expandedKeys = [data.id]
        this.fetchNodeData(data.id)
      } else if (data.type === RootType) {
        this.expandedKeys = [RootType]
        this.fetchNodeData('')
      }
      this.selectedNode = data

    },
    getPaddingLeft(level) {
      if (!level) return '8px'
      return 30 * level + 'px'
    },
    handleCommand(command, item) {
      console.log(command, item)
      switch (command) {
        case 'publish':
          this.handlePublish(item)
          break
        case 'formConfig':
          this.handleFormConfig(item)
          break
        case 'disable':
          this.handleDisable(item)
          break
        case 'enable':
          this.handleEnable(item)
          break
        case 'copy':
          this.handleCopy(item)
          break
        case 'publishRecord':
          this.handlePublishRecord(item)
          break
        case 'createFolder':
          this.handleCreateFolder(item)
          break
        case 'createFile':
          this.handleCreateFile(item)
          break
        case 'rename':
          this.handleRename(item)
          break
        case 'move':
          this.handleMove(item)
          break
        case 'delete':
          this.handleDelete(item)
          break
        default:
          break
      }
    },
    async handlePublish(item) {
      const data = {
        path: this.getPath(this.selectedNode),
        form: {
          name: item.nodeName,
        },
        nodeId: item.nodeId,
        nodeType: item.nodeType,
      }
      this.publishModalConfig = {
        visible: true,
        data
      }
    },
    handleFormConfig(item) {
      console.log(item)
    },
    handleDisable(item) {
      this.handleEnableDisable({ nodeType: item.nodeType, modeId: item.nodeId, enableStatus: 'DISABLE' })

    },
    handleEnable(item) {
      this.handleEnableDisable({ nodeType: item.nodeType, modeId: item.nodeId, enableStatus: 'ENABLE' })
    },
    async handleEnableDisable(item) {
      try {
        await apiEnableDisable({
          nodeType: item.nodeType,
          modeVO: {
            modeId: item.modeId,
            enableStatus: item.enableStatus
          }
        })
        this.$message.success('操作成功')
        this.fetchNodeData(this.selectedNode.id)
      } catch (error) {
        this.$message.error(getMessageError(error))
      }
    },
    handleCopy(item) {
      const path = this.getPath(this.selectedNode)
      const data = { id: item.nodeId, path: `${path} ＞ ${item.nodeName}`, form: { name: `${item.nodeName}-副本` } }
      console.log('data', data)
      this.copyModalConfig = {
        visible: true,
        data
      }
    },
    handlePublishRecord(item) {
      const routeData = {
        path: '/workflow/publishRecord',
        query: { 
          nodeId: item.nodeId,
        }
      }
      // Open in new tab
      const { href } = this.$router.resolve(routeData)
      window.open(href, '_blank')
    },
    handleCreateFolder(item) {
      const path = this.getPath(this.selectedNode)
      const data = { id: item.nodeId, path: `${path} ＞ ${item.nodeName}` }
      this.createFolderModalConfig = {
        visible: true,
        data
      }
    },
    handleCreateFile(item) {
      const path = this.getPath(this.selectedNode)
      const data = { id: item.nodeId, path: `${path} ＞ ${item.nodeName}` }
      this.createFileModalConfig = {
        visible: true,
        data
      }
    },
    getPath(item) {
      return item && Array.isArray(item.parentNodes) ? item.parentNodes.map(item => item.nodeName).join(' ＞ ') : ''
    },
    handleRename(item) {
      const data = {
        oldName: item.nodeName,
        nodeId: item.nodeId,
        nodeType: item.nodeType,
      }
      this.renameModalConfig = {
        visible: true,
        data
      }
    },
    handleMove(item) {
      const data = {
        nodeId: item.nodeId,
        nodeType: item.nodeType,
        path: this.getPath(this.selectedNode)
      }
      this.moveFileModalConfig = {
        visible: true,
        data
      }
    },
    async handleDelete(item) {
      this.loading = true
      try {
        await apiDeleteNode({
          nodeId: item.nodeId,
          nodeType: item.nodeType
        })
        this.$message.success('删除成功')
        this.loading = false
        this.fetchTreeData()
      } catch (error) {
        this.loading = false
        this.$message.error(getMessageError(error))
      }
    },

  }
};
</script>

<style lang="less" scoped>
.workflow-list-page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  padding: 27px 23px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin: 10px 20px 20px 40px;
  font-size: 14px;
  font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
  overflow: auto;

  div {
    box-sizing: border-box;
  }

  .empty-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 313px;
    margin-top: 60px;
  }

  .tree-box {
    width: 250px;
    background: #FCFCFC;
    border-radius: 10px;
    border: 1px solid #E4E7EB;
    height: 100%;
    padding: 12px 10px;
    margin-right: 20px;
    overflow: auto;
  }

  .workflow-tag-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    background: #FFEFEF;
    border-radius: 2px 4px;
    font-weight: 400;
    font-size: 10px;
    color: #F35555;
    font-style: normal;
    text-transform: none;
  }

  .workflow-tag-box {
    display: flex;
    gap: 4px;
    margin-left: 6px;
  }

  .workflow-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 4px;
    font-weight: 400;
    font-size: 10px;
    color: #2A61FF;
    background: rgba(42, 97, 255, 0.15);
    border-radius: 2px 4px;
    line-height: 10px;

    &-publish {
      background: rgba(42, 97, 255, 0.15);
    }

    &-draft {
      color: #5D687C;
      background: #EAEAEA;
    }
  }

  /*

    .tree-container {
      background: #FCFCFC;
      .icon-class {
        display: none;
      }
    }


    .custom-tree-node {
      position: relative;
      display: flex;
      height: 34px;
      border-radius: 6px;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;

      &.node-selected {
        background: #EAF0FF;
      }

      &:hover {
        background-color: #F4F4F5;
      }

      .node-wrapper {
        display: flex;
        align-items: center;

      }


      .icon-type {
        margin: 0 4px;
      }

      .tree-label {
        font-weight: 400;
        font-size: 14px;
      }
      .tree-label-disabled {
        color: #5D687C;
      }
      .tree-tag-disabled{
        background: #EAEAEA;
        border-radius: 2px 4px 2px 4px;
        padding: 2px 4px;
        font-size: 10px;
      }

    }
  }*/


  .right {
    flex: 1;

    .right-top {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .workflow-list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;

      .workflow-card {
        width: 250px;
        height: 195px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        border: 1px solid #E3E8EE;

        &:hover {
          border-color: #2A61FF;
        }

        &-content {
          height: 130px;
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          align-items: center;
          background: #F6F6F6;
          border-radius: 10px 10px 0px 0px;
          padding-top: 10px;

          .workflow-icon {
            width: 80px;
            height: 80px;
          }

          .dropdown-part {
            display: flex;
            justify-content: flex-end;
            width: 100%;
          }

          .ellipsis-btn {
            width: 100%;
            height: 18px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;
            padding-right: 12px;

            .dot {
              width: 3px;
              height: 3px;
              background: #5D687C;
              margin-right: 3px;
              border-radius: 100%;
            }
          }


        }

        &-footer {
          display: flex;
          flex-direction: column;
          padding: 12px 12px 0 16px;
          box-sizing: border-box;

          .workflow-card-name {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            font-weight: 500;
            font-size: 14px;
            color: #252D3D;

            .name-icon {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }

          .workflow-name-date {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #B5BECE;
          }
        }
      }
    }



  }
}

.dropdown-menu {
  width: 120px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px;
  border-radius: 10px;
  padding: 12px 0px;
  //margin-top: 0px;
  font-weight: 500;
  font-size: 14px;
  color: #252D3D;

  .el-popper .popper__arrow {
    background-color: red;
  }

  .el-dropdown-menu__item {
    padding: 8px 16px;
    line-height: 14px;

    &:hover {
      background: #F4F4F5 !important;
      color: #2A61FF;
    }
  }
}

::v-deep {
  .error-text {
    color: #F13636 !important;
  }

  .dropdown-menu-item {
    padding: 8px 16px;
    line-height: 14px;
  }

  .el-button+.el-button {
    margin-left: 0;
  }


}
</style>