<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-28 10:24:22
 * @Description: 
-->
<template>
  <el-form label-position="top" ref="flowFormRef" label-width="80px" :model="form" :rules="rules" class="flow-form">
    <el-form-item prop="name">
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">文件夹名称</span>
          <span class="flow-form-item-path">位置：{{ path }}</span>
        </div>
      </template>
      <el-input v-model="form.name"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
const validateFileName = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('请输入文件夹名称'));
  } else {
    callback();
  }
};
export default {
  name: "create-folder-modal",

  props: {
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        name: '',
      },
      rules: {
        name: [
          { validator: validateFileName, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async onOk() {
      try {
        const valid = await this.$refs.flowFormRef.validate()
        if (!valid) {
          return false;
        }
        return {...this.form}
      } catch (error) {
        return false
      }
    },
    
  },
  expose: ['onOk']

}
</script>
<style lang="less" scoped>
.flow-form {
  width: 540px;
}

.flow-form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 540px;

  &-label {
    height: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #252D3D;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  &-path {
    height: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #5D687C;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>