<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-27 15:50:54
 * @Description: 
-->
<template>
    <div>
      <div :class="className"  @click="openModal"><slot></slot></div>
        <flow-modal width="400px" title="删除文件夹" :visible="createFileVisible" @onOk="handleConfirm" @onCancel="handleCancel"
            :loading="loading" append-to-body button-status="warning" destroy-on-close>
          <div class="tip">
            <img :src="warningIcon" alt="warningIcon">
            <span>是否确认删除</span>
          </div>
        </flow-modal>
    </div>
</template>
<script>
import FlowModal from './flow-modal.vue';
import warningIcon from "@/assets/images/workflow/warning.svg"
export default {
    name: "create-folder-modal",
    components: {
        FlowModal
    },
    props: {
        onOk: {
            type: Function,
            default: () => { }
        },
      className: {
          type: String,
          default: ''
        }
    },
    data() {
        return {
            warningIcon,
            loading: false,
            createFileVisible: false,
        }
    },
    methods: {
      openModal(){
        this.createFileVisible = true
        console.log('this.createFileVisible', this.createFileVisible)
      },
        async handleConfirm() {
            try {
              if (this.onOk){
                this.loading = true
                console.log('this.onOk', this.onOk)
                await this.onOk()
                this.createFileVisible = false
              }
            }catch (e) {
              this.$message.error(e)
            }finally {
              this.loading = false
            }
        },
        handleCancel() {
            this.createFileVisible = false
        },

    }

}
</script>
<style lang="less" scoped>
.tip{
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>