<template>
  <el-tree v-bind="$attrs" class="tree-container" node-key="id" :default-checked-keys="checkedKeys"
    icon-class="icon-class" @node-click="handleNodeClick" :data="treeData" :props="defaultProps">
    <div slot-scope="{ node, data }" :class="{ 'node-selected': getSelected(data) }"
      :style="{ paddingLeft: getPaddingLeft(data.level) }" class="custom-tree-node">
      <div class="node-wrapper">
        <img v-if="!node.isLeaf" :src='node.expanded ? foldUrl : expandedUrl' class="icon-expanded" />
        <template v-if="data.id !== 'ROOT'">
          <img v-if="!data.status" :src="data.type === FolderType ? folderUrl : fileUrl">
          <img v-else :src="fileDisabledUrl">
        </template>
        <span class="tree-label" :class="{ 'tree-label-disabled': data.disabled }">
          <div class="label-text">{{ node.label }}</div>

          <el-checkbox style="margin: 0 30px 0 20px;" v-if="showCheckbox" class="checkbox"
            v-model="currentCheckedKeys[data[defaultProps.id]]" @change="(event) => change(event, { node, data, })">
          </el-checkbox>

        </span>

        <span class="tree-tag-disabled" v-if="data.disabled">禁用</span>
      </div>
    </div>
  </el-tree>

</template>
<script>
import fileDisabledUrl from "@/assets/images/workflow/file-disabled.svg"
import folderUrl from "@/assets/images/workflow/folder.svg"
import foldUrl from "@/assets/images/workflow/fold.svg"
import expandedUrl from "@/assets/images/workflow/expanded.svg"
import fileUrl from "@/assets/images/workflow/file-icon.svg"
import { FolderType, FileType, formatTreeData } from "../utils"
export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    props: {
      type: Object,
      default: () => ({ id: 'id', label: 'label', children: 'children' })
    },
    selectedNode: {
      type: Object,
      default: null
    },
    checkedKeys: {
      type: Array,
      default: () => []
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      FolderType,
      FileType,
      folderUrl,
      fileUrl,
      foldUrl,
      fileDisabledUrl,
      expandedUrl,
      treeData: [],
      currentCheckedKeys: {},
      defaultProps: {
        id: 'id',
        label: 'label',
        children: 'children'
      }
    }
  },
  methods: {
    change(event, data) {
      console.log('data', data);
      const key = data.data[this.defaultProps.id]
      this.currentCheckedKeys[key] = event
      this.$emit('checkbox-change', { checkedKeys: Object.keys(this.currentCheckedKeys), currentKey: key })
    },
    handleNodeClick(data) {
      this.$emit('node-click', data)
    },
    getSelected(data) {
      console.log('data', data);
      const selectedNode = this.selectedNode
      if (!selectedNode) {
        return false
      }
      const selectedNodeParentId = selectedNode.level === 0 ? selectedNode[this.defaultProps.id] : selectedNode.parentIds.at(0)
      const nodeId = selectedNode.parentIds.at(selectedNode.parentIds.length - 1)

      return selectedNodeParentId === data[this.defaultProps.id] || nodeId === data[this.defaultProps.id]
    },
    getPaddingLeft(level) {
      if (!level) return '8px'
      return 30 * level + 'px'
    },
  },
  watch: {
    data: {
      handler(val) {
        this.treeData = val
      },
      immediate: true
    },
    props: {
      handler(val) {
        this.defaultProps = { ...this.defaultProps, ...val }
      },
      immediate: true
    },
    checkedKeys: {
      handler(val) {
        const currentCheckedKeys = { ...this.currentCheckedKeys }
        for (const item of Object.keys(this.currentCheckedKeys)) {
          currentCheckedKeys[item] = val.includes(item)
        }
        console.log('currentCheckedKeys', currentCheckedKeys);
        
        this.currentCheckedKeys = currentCheckedKeys
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style scoped lang="less">
.tree-container {
  background: #FCFCFC;

}



::v-deep {
  .icon-class {
    display: none;
  }

  .custom-tree-node {
    position: relative;
    display: flex;
    height: 34px;
    border-radius: 6px;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;

    &.node-selected {
      background: #EAF0FF;
    }

    &:hover {
      background-color: #F4F4F5;
    }

    .node-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      column-gap: 4px;
    }




    .tree-label {
      display: flex;
      justify-content: space-between;
      flex: auto;
      font-weight: 400;
      font-size: 14px;
      .label-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .tree-label-disabled {
      color: #5D687C;
    }

    .tree-tag-disabled {
      background: #FFEFEF;
      color: #F35555;
      border-radius: 2px 4px 2px 4px;
      padding: 2px 4px;
      font-size: 10px;
      margin-left: 14px;
    }

  }

  .el-tree-node__content>.el-tree-node__expand-icon {
    padding: 0 !important;
    border-radius: 6px;
  }

  .el-tree-node {
    margin-bottom: 0px !important;
  }

  .el-tree-node__content:hover {
    background-color: initial !important;
  }

  .el-tree-node:focus>.el-tree-node__content {
    background-color: initial !important;
  }

  .el-tree-node__content {
    height: 100%;
    padding-left: 0 !important;
  }
}
</style>