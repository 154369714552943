var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "workflow-list-page",
      style: {
        width: _vm.sidebar.opened
          ? "calc(100vw - 330px)"
          : "calc(100vw - 140px)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tree-box" },
        [
          _c("flow-tree", {
            attrs: {
              "selected-node": _vm.selectedNode,
              data: _vm.treeData,
              "default-expanded-keys": _vm.expandedKeys,
            },
            on: { "node-click": _vm.nodeClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "div",
            { staticClass: "right-top" },
            [
              _c(
                "create-button",
                {
                  attrs: { "icon-url": _vm.folderUrl },
                  on: { click: _vm.createFolderClick },
                },
                [_c("div", [_vm._v("新建文件夹")])]
              ),
              _c(
                "create-button",
                {
                  attrs: { "icon-url": _vm.fileUrl },
                  on: { click: _vm.createFileClick },
                },
                [_c("div", [_vm._v("新建审批流")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "workflow-list" },
            [
              !_vm.workflowList.length
                ? _c("div", { staticClass: "empty-box" }, [
                    _c("img", { attrs: { src: _vm.emptyUrl, alt: "empty" } }),
                  ])
                : _vm._e(),
              _vm._l(_vm.workflowList, function (item) {
                return _c(
                  "div",
                  { key: item.nodeId, staticClass: "workflow-card" },
                  [
                    _c("div", { staticClass: "workflow-card-content" }, [
                      _c(
                        "div",
                        { staticClass: "dropdown-part" },
                        [
                          _c(
                            "el-dropdown",
                            {
                              on: {
                                command: (key) => _vm.handleCommand(key, item),
                              },
                            },
                            [
                              _c("div", { staticClass: "ellipsis-btn" }, [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "dot" }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: {
                                    slot: "dropdown",
                                    trigger: "click",
                                    "hide-on-click": false,
                                  },
                                  slot: "dropdown",
                                },
                                [
                                  item.type === _vm.FileType
                                    ? [
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "publish" } },
                                          [_vm._v("发布")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "formConfig" } },
                                          [_vm._v("表单配置")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              disabled: item.isProcess !== 1,
                                              command: "formConfig",
                                            },
                                          },
                                          [_vm._v("流程配置")]
                                        ),
                                        item.enableStatus === "ENABLE"
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "disable" } },
                                              [_vm._v("禁用")]
                                            )
                                          : _vm._e(),
                                        item.enableStatus === "DISABLE"
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "enable" } },
                                              [_vm._v("取消禁用")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "copy" } },
                                          [_vm._v("复制流程")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: { command: "publishRecord" },
                                          },
                                          [_vm._v("发布记录")]
                                        ),
                                        item.type === _vm.FolderType
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "createFolder",
                                                },
                                              },
                                              [_vm._v("新建文件夹")]
                                            )
                                          : _vm._e(),
                                        item.type === _vm.FolderType
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "createFile",
                                                },
                                              },
                                              [_vm._v("新建流程")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "rename" } },
                                          [_vm._v("重命名")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "move" } },
                                          [
                                            _vm._v(
                                              "\n                    移动至文件夹\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { staticStyle: { padding: "0" } },
                                          [
                                            _c(
                                              "warning-modal",
                                              {
                                                attrs: {
                                                  "on-ok": () =>
                                                    _vm.handleDelete(item),
                                                  "class-name":
                                                    "error-text dropdown-menu-item",
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  item.type === _vm.FolderType
                                    ? [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: { command: "createFolder" },
                                          },
                                          [_vm._v("新建文件夹")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "createFile" } },
                                          [_vm._v("新建流程")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "rename" } },
                                          [_vm._v("重命名")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "move" } },
                                          [
                                            _vm._v(
                                              "\n                    移动至文件夹\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { staticStyle: { padding: "0" } },
                                          [
                                            _c(
                                              "warning-modal",
                                              {
                                                attrs: {
                                                  "on-ok": () =>
                                                    _vm.handleDelete(item),
                                                  "class-name":
                                                    "error-text dropdown-menu-item",
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("img", {
                        staticClass: "workflow-icon",
                        attrs: {
                          src:
                            item.type === _vm.FileType
                              ? _vm.fileUrl
                              : _vm.folderUrl,
                          alt: "folder",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "workflow-card-footer" }, [
                      _c("div", { staticClass: "workflow-card-name" }, [
                        _c("img", {
                          staticClass: "name-icon",
                          attrs: {
                            src:
                              item.type === _vm.FileType
                                ? _vm.fileUrl
                                : _vm.folderUrl,
                            alt: "folder",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(item.nodeName))]),
                        item.type === _vm.FileType
                          ? _c("div", { staticClass: "workflow-tag-box" }, [
                              item.modeStatus === "PUBLISH"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "workflow-tag workflow-tag-publish",
                                    },
                                    [_vm._v("已发布")]
                                  )
                                : _vm._e(),
                              item.modeStatus === "DRAFT"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "workflow-tag workflow-tag-draft",
                                    },
                                    [_vm._v("草稿")]
                                  )
                                : _vm._e(),
                              item.enableStatus === "DISABLE"
                                ? _c(
                                    "span",
                                    { staticClass: "workflow-tag-disabled" },
                                    [_vm._v("禁用")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "workflow-name-date" }, [
                        _c("span", [_vm._v(_vm._s(item.updateUserName))]),
                        _c("span", [_vm._v(_vm._s(item.lastUpdateDate))]),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "新建文件夹",
                visible: _vm.createFolderModalConfig.visible,
                loading: _vm.loading,
              },
              on: {
                onOk: _vm.onOkCreateFolder,
                onCancel: _vm.handleCancelCreateFolder,
              },
            },
            [
              _vm.createFolderModalConfig.visible
                ? _c("create-folder-modal", {
                    ref: "createFolderModalRef",
                    attrs: { path: _vm.createFolderModalConfig.data.path },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "新建审批流",
                visible: _vm.createFileModalConfig.visible,
                loading: _vm.loading,
              },
              on: {
                onOk: _vm.onOkCreateFile,
                onCancel: _vm.handleCancelCreateFile,
              },
            },
            [
              _vm.createFileModalConfig.visible
                ? _c("create-file-modal", {
                    ref: "createFileModalRef",
                    attrs: { path: _vm.createFileModalConfig.data.path },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "400px",
                title: "重命名",
                visible: _vm.renameModalConfig.visible,
                data: _vm.renameModalConfig.data,
                loading: _vm.loading,
              },
              on: { onOk: _vm.onOkRename, onCancel: _vm.handleCancelRename },
            },
            [
              _vm.renameModalConfig.visible
                ? _c("rename-modal", {
                    ref: "renameModalRef",
                    attrs: { "old-name": _vm.renameModalConfig.data.oldName },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "复制流程",
                visible: _vm.copyModalConfig.visible,
                loading: _vm.loading,
              },
              on: { onOk: _vm.onOkCopyModal, onCancel: _vm.onCancelCopyModal },
            },
            [
              _vm.copyModalConfig.visible
                ? _c("create-file-modal", {
                    ref: "copyModalRef",
                    attrs: {
                      path: _vm.copyModalConfig.data.path,
                      defaultForm: _vm.copyModalConfig.data.form,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "移动至文件夹",
                visible: _vm.moveFileModalConfig.visible,
              },
              on: { onOk: _vm.onOkMoveFile, onCancel: _vm.onCancelMoveFile },
            },
            [
              _vm.moveFileModalConfig.visible
                ? _c("move-file-modal", {
                    ref: "moveFileModalRef",
                    attrs: { "node-id": _vm.moveFileModalConfig.data.nodeId },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "发布",
                visible: _vm.publishModalConfig.visible,
                loading: _vm.loading,
              },
              on: {
                onOk: _vm.onOkPublishModal,
                onCancel: _vm.onCancelPublishModal,
              },
            },
            [
              _vm.publishModalConfig.visible
                ? _c("publish-modal", {
                    ref: "publishModalRef",
                    attrs: {
                      path: _vm.publishModalConfig.data.path,
                      "default-form": _vm.publishModalConfig.data.form,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }