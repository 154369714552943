<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-29 09:38:13
 * @Description: 
-->
<template>
    <el-dialog v-bind="$attrs" v-on="$listeners" :visible="visible" @close="handleCancel" custom-class="flow-dialog"
        :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>

        <!-- 修改内容部分 -->
        <div class="custom-dialog-content">
            <slot>默认内容</slot>
        </div>

        <!-- 修改操作按钮部分 -->
        <template #footer>
            <div class="custom-dialog-footer">
                <slot name="footer">
                    <el-button class="flow-button" :class="{ 'flow-button-warning': buttonStatus === 'warning' }"
                        @click="handleCancel">取消</el-button>
                    <el-button class="flow-button "
                        :class="{ 'flow-button-warning': buttonStatus === 'warning', 'flow-button-warning-primary': buttonStatus === 'warning', 'flow-button-primary': buttonStatus !== 'warning' }"
                        type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
                </slot>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "flow-modal",
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        customClass: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false
        },
        buttonStatus: {
            type: String,
            default: 'default'
        },
    },
    methods: {
        handleCancel() {
            this.$emit("onCancel"); // 自定义取消事件
        },
        handleConfirm() {
            this.$emit("onOk"); // 自定义确认事件
        },
    },
};
</script>
<style scoped lang="less">
.flow-button {
    border-radius: 8px;
    border: 1px solid #2A61FF;

    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #2A61FF;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 12px !important;

    &-primary {
        background: #2A61FF;
        color: #fff;
    }
}

.flow-button-warning {
    color: #F35555;
    border-color: #F35555;

    &-primary {
        background: #F35555 !important;
        color: #fff;
    }
}

::v-deep(.flow-dialog) {
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;

    .el-dialog__header {
        padding: 30px !important;
    }
}
</style>