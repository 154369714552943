var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    _vm._g({ staticClass: "create-button" }, _vm.$listeners),
    [
      _c("div", { staticClass: "create-button-content" }, [
        _c("div", { staticClass: "create-button-text" }, [
          _c("img", {
            staticClass: "button-folder-icon",
            attrs: { src: _vm.iconUrl, alt: "folder" },
          }),
          _c(
            "span",
            { staticClass: "create-button-text" },
            [_vm._t("default")],
            2
          ),
        ]),
        _c("img", {
          staticClass: "create-button-icon",
          attrs: { src: _vm.createUrl, alt: "create" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }