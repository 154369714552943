<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-29 14:23:17
 * @Description: 
-->
<template>
  <div v-loading="loading">
    <flow-tree @node-click="nodeClick" :default-expanded-keys="expandedKeys" class="warp" :data="treeData"
      :show-checkbox="true" :checked-keys="checkedKeys" @checkbox-change="checkboxChange">
    </flow-tree>
  </div>

</template>
<script>
import { apiGetPreNodeTree } from "../../../../../api/ruge/workflow/workflow";
import { arrayToTree, formatTreeData, getTreeData } from "../utils";
import FlowTree from "./flow-tree.vue";
import warningIcon from "@/assets/images/workflow/warning.svg"
export default {
  name: "move-file-modal",
  components: {
    FlowTree
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.fetchTreeData()
  },
  data() {

    return {
      expandedKeys: [],
      warningIcon,
      loading: true,
      checkedKeys: [],
      treeData: []
    }
  },
  methods: {
    fetchTreeData() {
      this.loading = true
      apiGetPreNodeTree().then(res => {
        const data = res.filter(item => item.nodeType !== 'mode' && item.nodeId !== this.nodeId && item.parentId !== this.nodeId)
        this.treeData = formatTreeData(arrayToTree(getTreeData(data)))
        this.expandedKeys = this.treeData.map(item => item.id)
      }).catch(err => {
        console.error(err)
        this.$message.error(getMessageError(err))
      }).finally(() => {
        this.loading = false
      })
    },
    checkboxChange(data) {
      this.checkedKeys = [data.currentKey]
    },
    onOk() {
      if (!this.checkedKeys.length) {
        this.$message.error('请选择文件夹')
        return
      }
      return this.checkedKeys
    },
    nodeClick(data) {
      this.expandedKeys = [...this.expandedKeys, data.id]
    }
  },
  expose: ['onOk']

}
</script>
<style lang="less" scoped>
.warp {
  padding: 20px 18px;
  border-radius: 10px;
  background: #FCFCFC;
  border: 1px solid #E4E7EB;
  height: 360px;
  box-sizing: border-box;
  overflow-x: auto;
}

.tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  padding-right: 62px;
}

.checkbox {
  height: 14px;
}

::v-deep {

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2A61FF;
    border-color: #2A61FF;
  }
}
</style>