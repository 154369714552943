var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.className, on: { click: _vm.openModal } },
        [_vm._t("default")],
        2
      ),
      _c(
        "flow-modal",
        {
          attrs: {
            width: "400px",
            title: "删除文件夹",
            visible: _vm.createFileVisible,
            loading: _vm.loading,
            "append-to-body": "",
            "button-status": "warning",
            "destroy-on-close": "",
          },
          on: { onOk: _vm.handleConfirm, onCancel: _vm.handleCancel },
        },
        [
          _c("div", { staticClass: "tip" }, [
            _c("img", { attrs: { src: _vm.warningIcon, alt: "warningIcon" } }),
            _c("span", [_vm._v("是否确认删除")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }