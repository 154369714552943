var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            visible: _vm.visible,
            "custom-class": "flow-dialog",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "destroy-on-close": "",
          },
          on: { close: _vm.handleCancel },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "custom-dialog-footer" },
                      [
                        _vm._t("footer", function () {
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "flow-button",
                                class: {
                                  "flow-button-warning":
                                    _vm.buttonStatus === "warning",
                                },
                                on: { click: _vm.handleCancel },
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "flow-button",
                                class: {
                                  "flow-button-warning":
                                    _vm.buttonStatus === "warning",
                                  "flow-button-warning-primary":
                                    _vm.buttonStatus === "warning",
                                  "flow-button-primary":
                                    _vm.buttonStatus !== "warning",
                                },
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.handleConfirm },
                              },
                              [_vm._v("确认")]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "custom-dialog-content" },
        [
          _vm._t("default", function () {
            return [_vm._v("默认内容")]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }